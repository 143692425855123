import * as React from 'react'
import '../assets/css/index.css'
import Navigation from '../components/Navigation'; 
import Footer from '../components/Footer'
import { Container, Modal, Col, Row } from "react-bootstrap";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
// markup
const IndexPage = () => {
  return (
    <main>
      <Navigation/>
      <GatsbySeo
        title='Privacy Policy - Pinches Cue Club & The Venue - Snooker, Pool and Darts | Norwich Snooker and Pool Club | Private Function Room'
        description='Looking for a Snooker or Pool club in Norwich? Book a table before or come down to see if we have any availability'
        />
      <div className='black-section'>
      <Container>
            <Row>
            <h2 className='pt-3 pb-3'>Information</h2>
              <Col lg={6} md={12} sm={12} xs={12} className="black-section ">
              <h1 className=" text-center pt-4 pb-4">Privacy Policy</h1>
                <div className="">
                  <p>
                    The policy: This privacy policy notice is served by
                    pinchescueclub.co.uk. The purpose of this policy is to explain to
                    you how we control, process, handle and protect your
                    personal information through the business and while you
                    browse or use this website. If you do not agree to the
                    following policy you may wish to cease viewing / using this
                    website, and or refrain from submitting your personal data
                    to us.
                  </p>
                  <h4 className="mb-4">Policy key definitions:</h4>
                  <ul>
                    <li>
                      “I”, “our”, “us”, or “we” refer to the business,
                      pinchescueclub.co.uk
                    </li>
                    <li>
                      “you”, “the user” refer to the person(s) using this
                      website.
                    </li>
                    <li>GDPR means General Data Protection Act.</li>
                    <li>
                      PECR means Privacy &amp; Electronic Communications
                      Regulation.
                    </li>
                    <li>ICO means Information Commissioner’s Office.</li>
                    <li>
                      Cookies mean small files stored on a users computer or
                      device.
                    </li>
                  </ul>
                  
                  <h4 className="sub-heading">Key principles of GDPR:</h4>
                  <p>
                    Our privacy policy embodies the following key priciples; (a)
                    Lawfullness, fairness and transpatrency, (b) Purpose
                    limitation, (c) Data minimisation, (d) Accurancy, (e)
                    Storage limitation, (f) Integrity and confidence, (g)
                    Accountability.
                  </p>
                  <h4 className="mb-4">Processing of your personal data</h4>
                  <p>
                    Under the GDPR (General Data Protection Regulation) we
                    control and / or process any personal information about you
                    electronically using the following lawful bases.Data
                    retention period: We will continue to process your
                    information under this basis until you withdraw consent or
                    it is determined your consent no longer exists. Sharing your
                    information: We do not share your information with third
                    parties. / We do share your personal information with third
                    parties and they include;.
                  </p>
                  <h4 className="mb-4">Your individual rights</h4>
                  <p>
                    Under the GDPR your rights are as follows. You can read more
                    about&nbsp;
                    <a
                      title="your rights in details under the GDPR"
                      href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      your rights in details here
                    </a>
                    ;
                  </p>
                  <ul>
                    <li>the right to be informed;</li>
                    <li>the right of access;</li>
                    <li>the right to rectification;</li>
                    <li>the right to erasure;</li>
                    <li>the right to restrict processing;</li>
                    <li>the right to data portability;</li>
                    <li>the right to object; and</li>
                    <li>
                      the right not to be subject to automated decision-making
                      including profiling.
                    </li>
                  </ul>
                  <p>
                    You also have the right to complain to the ICO [
                    <a
                      title="The ICO"
                      href="http://www.ico.org.uk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.ico.org.uk
                    </a>
                    ] if you feel there is a problem with the way we are
                    handling your data.
                  </p>
                  <p>
                    We handle subject access requests in accordance with the
                    GDPR.
                  </p>
                  <h4>Internet cookies</h4>
                  <p>
                    We use cookies on this website to provide you with a better
                    user experience. We do this by placing a small text file on
                    your device / computer hard drive to track how you use the
                    website, to record or log whether you have seen particular
                    messages that we display, to keep you logged into the
                    website where applicable, to display relevant adverts or
                    content, referred you to a third party website.
                  </p>
                  <p>
                    Some cookies are required to enjoy and use the full
                    functionality of this website.
                  </p>
                  <p>
                    We use a cookie control system which allows you to accept
                    the use of cookies, and control which cookies are saved to
                    your device / computer. Some cookies will be saved for
                    specific time periods, where others may last indefinitely.
                    Your web browser should provide you with the controls to
                    manage and delete cookies from your device, please see your
                    web browser options.
                  </p>
                </div>
            </Col>
          </Row>
      </Container>
      <Footer/>
      </div>
    </main>
  )
}

export default IndexPage
